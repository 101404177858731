body {
  margin: 0;    
	Font-Family: 'Cabin', Sans-Serif;
    color: var(--color-text-primary); 
    word-wrap: normal; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eff2f5;
  color: #1a77f2;
  max-width: 100vw;
  max-height: 100vh;
}

h1 {
  color: #1a77f2;
}

h1, h2, h3 {
	Font-Family: 'Roboto Condensed', Sans-Serif;
}

header {
  background-color: #1a77f2;
  color: #eff2f5;
  text-align: center;
  display: flex;
  justify-content: space-between;

}

footer {
  text-align: center;
  font-size: 14px;
  padding: .3rem;
  background-color: #eff2f5;
  color: #1a77f2;
}

a:hover img {
  box-shadow: 0 0 10px #33ff29;
  transition: 0.61s -10ms;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}